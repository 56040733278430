import React from "react";

import Layout from "../../components/layout";
import SEO from "../../components/seo";

import Grid from "@material-ui/core/Grid";

import style from "../../components/Dashboards/style";
import ReportManager from "../../components/ReportScheduler/ReportManager";
import {checkAuth} from "../../helpers/auth";

class ReportSchedulerPage extends React.Component {
    componentDidMount() {
        checkAuth('media-planner');
    }

    render() {
        return (
            <Layout>
                <SEO title="Welcome"/>
                <Grid container justify="center" spacing={2} style={style.mainContainer}>
                    <Grid item  xs={12} >
                        <div style={style.tabContainer}>
                            <ReportManager/>
                        </div>
                    </Grid>
                </Grid>
            </Layout>
        );
    }
}

export default ReportSchedulerPage;
