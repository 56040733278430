import React from "react";
import axios from "axios";

import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
} from "@material-ui/core"
import querystring from "querystring"

import Loading from "../Miscellaneous/Loading";
import { ReportScheduler } from '../ReportScheduler/popup';

import {
    Table,
    TableHead,
    TableRow,
    TableCell,
    TableBody,
    MenuItem,
    Button,
    Menu,
    Checkbox,
    TableSortLabel,
    Typography
} from "@material-ui/core";

import ScrollTopLeft from "../Miscellaneous/scrollTopLeft";

const moment = require("moment-timezone");
const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;


const hiddenColsDefault = [];

class ReportManager extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            data: false,
            activeRow: {},
            defaultHiddenColumns: [...hiddenColsDefault],
            //columnsAnchorEl: null,
            //showAssetsPopup: false,
            loading: true,
            //filterParams: {},
            allColumns: [
                {
                    "label": 'View ID',
                    "fieldName": 'rep_view_id',
                    "width": 50
                },
                {
                    "label": 'Report',
                    "fieldName": 'report',
                    "minWidth": 500,
                },
                {
                    "label": 'View Name',
                    "fieldName": 'view_name',
                    "minWidth": 500,
                    "onClick": "openView"
                },
                {
                    "label": 'View owner',
                    "fieldName": 'user_name',
                    "minWidth": 500
                },
                {
                    "label": 'Vendor`s mail',
                    "fieldName": 'vendor_mail',
                    "minWidth": 500,
                },
                {
                    "label": 'Local Time',
                    "fieldName": 'time',
                    "minWidth": 100,
                    render: (itm) => {
                        return moment.utc(itm, 'HH:mm').tz(timezone).format('hh:mm A')
                    }
                },
                {
                    "label": 'Report timezone',
                    "fieldName": 'timezone',
                    "minWidth": 200,
                    render: (itm) => {
                        return itm === timezone ? '-' : itm
                    }
                },
                {
                    "label": 'Days',
                    "fieldName": 'days',
                    "minWidth": 200,
                    render: (itm) => {
                        const days = JSON.parse(itm).sort();
                        return ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'].filter((e, idx) => days.includes(idx)).join(', ');
                    }
                },
                {
                    "label": 'Columns',
                    "fieldName": 'columns',
                    "minWidth": 200,
                    render: (itm) => {
                        const columns = (itm && itm !== '') ? JSON.parse(itm) : null;
                        return columns ? <>{columns.map(c => <div key={c}>{c}</div>)}</> : '-';
                    }
                },
                {
                    "label": 'Last Send',
                    "fieldName": 'last_send',
                    "minWidth": 200,
                    render: (itm) => itm ? moment.utc(itm).tz(timezone).format('YYYY-MM-DD hh:mm A') : '-'
                },
                // {
                //     "label": 'Reports List',
                //     "fieldName": 'list',
                //     "minWidth": 200,
                //     "onClick": "openList"
                // },
                {
                    "label": 'Edit',
                    "default": 'edit',
                    "minWidth": 200,
                    "onClick": "edit"
                },
                {
                    "label": 'Delete',
                    "default": 'delete',
                    "minWidth": 200,
                    "onClick": "delete"
                }
            ],
            pagination: {
                size: 25,
                page: 0,
                totalCount: 0
            },
            sort: {
                asc: false,
                column: 'view_id'
            },
            reportScheduler: undefined,
            editReport: undefined
        };

        this.handleReportScheduleSave = this.handleReportScheduleSave.bind(this);
    }

    componentDidMount() {
        this.setState({ loading: true }, this.loadData);
    }

    async loadData() {
        this.setState({
            ...this.state,
            loading: true
        })
        const { data } = await axios.get(
            process.env.SERVER_ENDPOINT + `/rep_views/scheduled?orderBy=${this.state.sort.column}&orderDir=${this.state.sort.asc ? 'asc' : 'desc'}`
        );

        this.setState({
            data: data,
            loading: false,
        });
    }

    sortBy(col) {
        let direction = this.state.sort.asc;
        let column = this.state.sort.column;

        if (col === this.state.sort.column) {
            direction = !this.state.sort.asc;
        } else {
            column = col;
        }

        this.setState({
            ...this.state,
            sort: {
                column: column,
                asc: direction
            }
        }, this.loadData);
    }

    isColDisabled(col) {
        return this.state.defaultHiddenColumns.indexOf(col) > -1;
    }

    toggleColumn(col, value) {
        let defCols = [...this.state.defaultHiddenColumns];
        if (value) {
            defCols.splice(defCols.indexOf(col), 1);
        } else {
            defCols.unshift(col);
        }
        this.setState({
            ...this.state,
            defaultHiddenColumns: defCols
        });

        localStorage.setItem(`hiddenColumns${document.location.pathname}`, JSON.stringify(defCols));
    }

    switchColPreset(param) {
        let hiddenCols = [];
        if (param === 'default') {
            hiddenCols = [...hiddenColsDefault];
        } else if (param === 'none') {
            hiddenCols = this.state.allColumns.map(col => col.fieldName);
        }

        localStorage.setItem(`hiddenColumns${document.location.pathname}`, JSON.stringify(hiddenCols));

        this.setState({
            ...this.state,
            defaultHiddenColumns: hiddenCols
        })
    }

    toggleColsSettings(event) {
        this.setState({
            ...this.state,
            columnsAnchorEl: event.currentTarget,
            colsSettingsOpened: !this.state.colsSettingsOpened
        })
    }

    format(value, type) {
        if (!type) return value;
        switch (type) {
            case 'date':
                return moment(value).format('MM/DD/YYYY');

            case 'month':
                return moment().month(value.toString()).add('months', -1).format('MMMM');
            case 'money':
                return new Intl.NumberFormat('en-US', {
                    style: 'currency',
                    currency: 'USD',
                }).format(value);

            default:
                return value;
        }
    }

    openView(itm) {
        window.location.href = `/media-planner/${itm.report.replace('@','/')}#?id=${itm.rep_view_id}`;
    }

    edit(itm) {
        this.setState({
            editReport: itm.rep_view_id,
            reportScheduler: itm
        })
    }
    async delete(itm) {
        await axios.delete(`${process.env.SERVER_ENDPOINT}/rep_views/schedule`, { data: { id: itm.id } });
        this.setState({ loading: true }, async () => await this.loadData())
    }

    colClickMethod(params, col) {
        eval(`this.${col.onClick}`).call(this, params);
    }

    handleSelect(params) {
        this.setState({
            ...this.state,
            filterParams: params
        }, this.loadData);
    }

    async handleReportScheduleSave(data) {

        if (this.state.reportScheduler) {
            const sc = this.state.reportScheduler;
            const body = {
                rep_view_id: Object.values(querystring.parse(window.location.href))[0],
                time: sc.time.getUTCHours() + ':' + sc.time.getUTCMinutes() + ':00',
                vendor_mail: sc.mail,
                days: JSON.stringify(sc.days),
                id: sc.id,
                timezone,
                columns: sc.columns
            }
            await axios.post(`${process.env.SERVER_ENDPOINT}/rep_views/schedule`, body);

            this.setState({ editReport: undefined, loading: true }, async () => await this.loadData())
        } else {

        }
    }

    render() {
        const { data } = this.state;
        let headers = this.state.allColumns;

        return (
            <>
                <Table className="reports__table media__planner" stickyHeader>
                    <TableHead>
                        <TableRow className="reports__header-sticky">
                            <TableCell key={`col_title`} colSpan={headers.length}>
                                <div className="reports__header_container" style={{ minWidth: '930px' }}>
                                    <h3>Report Schedule Manager</h3>

                                    <Menu
                                        id="columns-menu"
                                        keepMounted
                                        anchorEl={this.state.columnsAnchorEl}
                                        open={Boolean(this.state.colsSettingsOpened)}
                                        onClose={this.toggleColsSettings.bind(this)}
                                    >
                                        <Typography style={{ height: '30px' }}>
                                            <span style={{ fontSize: '14px', float: 'right' }}>
                                                Select:
                                            <button onClick={() => this.switchColPreset('all')} className="filter_select-allnone">All</button>
                                                <button onClick={() => this.switchColPreset('none')} className="filter_select-allnone">None</button>
                                                <button onClick={() => this.switchColPreset('default')} className="filter_select-allnone">Default</button>
                                            </span>
                                        </Typography>

                                        {headers.map((d, j) => d ? (
                                            d.type !== 'template' ?
                                                <MenuItem onClick={() => this.toggleColumn(d.fieldName, (this.state.defaultHiddenColumns.indexOf(d.fieldName) > -1))} key={`select_col_key_${j}`} value={d.fieldName}>
                                                    <Checkbox checked={!this.isColDisabled(d.fieldName)} value={d.fieldName} />
                                                    {d.label}
                                                </MenuItem>
                                                : null
                                        ) : null)}
                                    </Menu>

                                </div>
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            {headers.map((col, i) => (
                                !this.isColDisabled(col.fieldName) && !this.state.loading ?
                                    <TableCell className={`results__col_${i}`} key={`col_${i}`} style={col.fieldName === 'landing_page_id' ? { width: 50 } : {}}>
                                        <TableSortLabel
                                            active={this.state.sort.column === col.fieldName}
                                            direction={this.state.sort.asc ? 'asc' : 'desc'}
                                            onClick={() => this.sortBy(col.fieldName)}
                                        >
                                            {col.label}
                                        </TableSortLabel>
                                    </TableCell>
                                    : null
                            ))}
                        </TableRow>
                    </TableHead>

                    <TableBody>
                        {!data || this.state.loading ? (
                            <TableRow style={{ backgroundColor: '#fff' }}>
                                <TableCell colSpan={10}>
                                    <Loading />
                                </TableCell>
                            </TableRow>

                        ) : (
                                this.state.data.map((row, i) => (
                                    <TableRow key={i}>
                                        {headers.map((col, j) => (
                                            !this.isColDisabled(col.fieldName) ?
                                                <TableCell onClick={col.onClick ? () => this.colClickMethod(row, col) : null}
                                                    style={{ textDecoration: col.onClick ? 'underline' : 'none', cursor: col.onClick ? 'pointer' : 'inherit' }}
                                                    key={`_col_row_${j}_${i}`}>
                                                    {col.default ? col.default :
                                                        col.render ? col.render(row[col.fieldName]) :
                                                            col.link ? <a style={{ color: 'rgba(0, 0, 0, 0.87)', textDecoration: 'underline' }} href={row[col.fieldName]}>{row[col.fieldName]}</a> :
                                                                col.map ? col.map[row[col.fieldName]] :
                                                                    row[col.fieldName] && row[col.fieldName] !== null && row[col.fieldName] !== '' ? this.format(row[col.fieldName], col.type) :
                                                                        '-'}
                                                </TableCell>
                                                : null
                                        ))}
                                    </TableRow>
                                ))
                            )}
                    </TableBody>
                </Table>
                <ScrollTopLeft />
                {this.state.editReport &&
                    <Dialog open={this.state.editReport !== undefined}>
                        <DialogTitle>Send Report Details</DialogTitle>
                        <DialogContent>
                            <ReportScheduler
                                viewId={Object.values(querystring.parse(window.location.href))[0] || this.state.editReport}
                                onChange={(data) => { this.setState({ reportScheduler: { ...this.state.reportScheduler, ...data } })} } />
                        </DialogContent>
                        <DialogActions>
                            <Button color="primary" onClick={() => this.setState({ editReport: undefined })}> Cancel</Button>
                            <Button color="primary" variant="contained" onClick={this.handleReportScheduleSave} disabled={!this.state.reportScheduler}>
                                {this.state.reportScheduler && this.state.reportScheduler.time ? 'Save' : 'Send now'}
                            </Button>
                        </DialogActions>
                    </Dialog>
                }
            </>
        );
    }
}

export default ReportManager;
